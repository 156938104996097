export const apiBaseUrl = 'https://api.gateway.digitaltack.com';
export const websocketBaseUrl = 'wss://api.gateway.digitaltack.com/graphql';
export const sso = {
  url: 'https://accounts.digitaltack.com'
}
const cdnBase = 'https://statics.digitaltack.com';
export const cdn = {
  app: `${cdnBase}/gateway-webclient/`,
  images: `${cdnBase}/:scope/:type/:uuid/:name`,
}
