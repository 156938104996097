import React from 'react';

export default function IconButton({ className = "mb0 primary hover-secondary", title, onClick, icon }) {
  return (
    <div
      className={className}
      title={title}
      onClick={onClick}
    >{icon}</div>
  )
}
