import securedWithProfile from 'hocs/securedWithProfile';
import Homepage from 'common/containers/Homepage';
import Users from 'common/containers/Users';
import Profile from 'common/containers/Profile';
import Roles from 'common/containers/Roles';
import Login from 'containers/Login';
import EntryPoints from 'containers/EntryPoints';
import { PROJECT_ROLES } from 'config';

export const root = {
  component: securedWithProfile(Homepage),
  title: 'Homepage',
  description: 'Página de inicio de la aplicación',
  paths: {
    en: '/',
    es: '/',
    pt: '/',
  }
};

export const entryPoints = {
  component: securedWithProfile(EntryPoints),
  paths: {
    en: '/entryPoints',
    es: '/entryPoints',
    pt: '/entryPoints',
  },
  title: 'EntryPoints'
};

export const login = {
  component: Login,
  title: 'Login',
  paths: {
    en: '/login',
    es: '/login',
    pt: '/login',
  },
  hideHeader: true,
  hideFooter: true,
  hideSidebar: true,
  hideFromNav: true,
  customLayout: 'flex items-center justify-center vh-100',
};

export const users = {
  component: securedWithProfile(Users),
  title: 'Users',
  description: 'Página de gestión de usuarios',
  paths: {
    en: '/users',
    es: '/users',
    pt: '/users',
  },
  roles: [PROJECT_ROLES.SUPER_ADMIN]
};

export const roles = {
  component: securedWithProfile(Roles),
  title: 'Roles',
  description: 'Página para la gestión de roles',
  paths: {
    en: '/roles',
    es: '/roles',
    pt: '/roles',
  },
  roles: [PROJECT_ROLES.SUPER_ADMIN]
};

export const profile = {
  component: securedWithProfile(Profile),
  title: "Profile",
  description: "Account profile page",
  paths: {
    en: '/account/profile',
    es: '/account/profile',
    pt: '/account/profile',
  },
  hideFromNav: true
};


