export const apiBaseUrl = 'https://pre.api.contacts.zubilabs.net/';
export const websocketBaseUrl = 'wss://pre.api.contacts.zubilabs.net/graphql';
export const sso = {
  url: 'https://pre.accounts.digitaltack.com'
}
const cdnBase = 'https://statics.digitaltack.com';
export const cdn = {
  app: '/',
  images: `${cdnBase}/:scope/:type/:uuid/:name`,
}
