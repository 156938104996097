import React from 'react';
import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import getRoute, { root } from 'routes/nav';
// import TopbarMail from './TopbarMail';
// import TopbarNotification from './TopbarNotification';
// import TopbarSearch from './TopbarSearch';
// import TopbarLanguage from './TopbarLanguage';

const Topbar = ({
  changeMobileSidebarVisibility, changeSidebarVisibility, profile, logout
}) => {
  return (
  <div className="topbar">
    <div className="topbar__left">
      <TopbarSidebarButton
        changeMobileSidebarVisibility={changeMobileSidebarVisibility}
        changeSidebarVisibility={changeSidebarVisibility}
      />
      <Link className="topbar__logo" to={getRoute(root)} />
    </div>
    <div className="topbar__right">
      <div className="topbar__right-search">
        {/* <TopbarSearch /> */}
      </div>
      <div className="topbar__right-over">
        {/* <TopbarNotification />
        <TopbarMail new /> */}
        <TopbarProfile 
          profile={profile} 
          logout={logout}
        />
        {/* <TopbarLanguage /> */}
      </div>
    </div>
  </div>
)};

export default Topbar;
