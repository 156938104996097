import { withEffects } from 'redux/refractSideEffects';
import { profileToProps } from 'pipes/profile';
import Topbar from './Topbar';
import './topbar.css';

const aperture = ({ store }) => component => profileToProps(component, store)

const handler = () => () => null

export default withEffects(handler)(aperture)(Topbar, 'Topbar');
