import React from 'react';
import Sidebar from 'common/structure/Sidebar/Sidebar';
import SidebarLink from 'common/structure/Sidebar/SidebarLink';
import { AboutInfoButton, AboutReportButton } from 'common/components/About';
import { profileCanSeeRoute } from 'utils';
import * as routes from 'routes/nav';
import './SideBar.css';

export default ({ profile, height, route }) => {
  if (!profile || !height || (route && route.hideSidebar)) return null;
  const sidebarContent = <React.Fragment>
    {
      Object.keys(routes).map((route) => {
        const showLink = profileCanSeeRoute({ profile, routes, route })
        if(
          routes[route].hideFromNav ||
          !showLink || 
          route === "default" ||
          route[0] === '_'
        ) return null;
        const contextRoute = routes[route];
        return <SidebarLink
          key={contextRoute.title}
          title={t(`${contextRoute.title}`)}
          icon="user"
          route={contextRoute.paths.es}
        />
      })
    }
  </React.Fragment>
  
  return (
    <div
      id="sidebar"
      className="fixed overflow-auto"
      style={{ height: `calc(100vh - ${height}px)`, top: `${height}px`, width: '240px', userSelect: 'none' }}
    >
      <Sidebar 
        sidebar={{ 
          show: false,
          content: sidebarContent}}
        changeToDark={() => { return null}}
        changeToLight={() => { return null}}
        changeMobileSidebarVisibility={() => { return null}}
      />
      <div className="absolute bottom-0 z-999 m-0 pa0">
        <div className="bold-text" style={{ height: '3rem' }}>
          <AboutReportButton />
        </div>
        <div className="sidebar__link bold-text" style={{ height: '3rem' }}>
          <AboutInfoButton
            name={'Gateway'}
            logo={'/images/logo/logo-digitaltack.png'}
            description={t('El gateway te permite crear y administrar la certificación SSL y proxy de los diferentes puntos de entrada definidos')}
          />
        </div>
      </div>
    </div>
  )
}
