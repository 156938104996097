
import React, { useState, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Select, Input, InputSearch } from 'common/components/Form';
import Toggle from 'common/components/Toggle';
import Button from 'common/components/Button';
import CustomDataTable from 'common/components/CustomDataTable';
import IconButton from 'common/components/CustomDataTable/IconButton';
import { showModal, closeModal } from 'dispatcher/modal';
import { numericDateFormat, dateToLocaleString } from 'utils/date';
import { 
  GET_ENTRYPOINTS, 
  UPDATE_ENTRYPOINT, 
  DELETE_ENTRYPOINT, 
  RENEW_ENTRYPOINT,
  ADD_ENTRYPOINT } from 'apollo/queries';
import Spinner from 'common/components/Spinner';
import TrashIcon from 'mdi-react/TrashIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import ShieldRefreshIcon from 'mdi-react/ShieldRefreshIcon';
import './EntryPoints.css';

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <div className="flex items-center">
    <InputSearch
      id="search-input"
      className="SearchInput br2 ml5"
      defaultValue={ filterText }
      searchAction={(e) => e.value ? onFilter(e.value) : onClear()}
      placeholder={t('Search...')} 
      actionDelayOnChange={400}
      actionMinValueLength={1}
      autoFocus={true}
    />
  </div>
);

export default function EntryPoints() {
  const { loading, error, data } = useQuery(GET_ENTRYPOINTS);
  const [updateEntrypoint] = useMutation(UPDATE_ENTRYPOINT, {
    onError: (e) => { console.log('ENTRYPOINT UPDATE ERROR', e) }
  });
  const [deleteEntryPoint] = useMutation(DELETE_ENTRYPOINT, {
    onError: (e) => { console.log('ENTRYPOINT DELETE ERROR', e) }
  });
  const [renewEntryPoint] = useMutation(RENEW_ENTRYPOINT);
  const [addEntrypoint] = useMutation(ADD_ENTRYPOINT);

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const handleAddSubmit = (e) => {
    e.preventDefault();
    addEntrypoint({
      variables: {
        origin: e.target.elements.origin.value,
        destination: e.target.elements.destination.value,
        status: JSON.parse(e.target.elements.status.value)
      },
      refetchQueries: [
          { query: GET_ENTRYPOINTS }
      ]
    });
    closeModal();
  };

  const AddModal = () => {
    return <form className="flex flex-column items-center justify-center pa8" onSubmit={handleAddSubmit}>
      <Input 
        id="origin"
        type="origin"
        label={t('common:origin')}
        required
        placeholder="origin"
      />
      <Input 
        id="destination"
        type="destination"
        label={t('common:destination')}
        required
        placeholder="destination"
      />
      <Select
        label={t('components:leadDetail:editModal:status')}
        id="status"
        required
        placeholder="status"
      >
        <option value={true}>Active</option>
        <option value={false}>Disabled</option>
      </Select>
      <Button
        primary
        type="submit"
        className="center"
      >
        Add entrypoint
      </Button>
    </form>
  }

  const AddEntryPointComponent = () => {
    return <div className="mr-auto">
      <Button
        primary
        type="button"
        onClick={(e) => {
          e.preventDefault()
          return showModal({
          classes: "db center mt7 w-80 ttu",
          children: <AddModal />,
          className: "bg-white"
        })}}
      >+ Add new entrypoint</Button>
    </div>
  }

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return <React.Fragment>
      <AddEntryPointComponent />
      <FilterComponent onFilter={e => setFilterText(e)} onClear={handleClear} filterText={filterText} />
    </React.Fragment>;
  }, [filterText, resetPaginationToggle]);

  if (loading) return <Spinner className="w3 h3 absolute-center" />;
  if (error) { // need the status here
    // return <ErrorPage {...error}/>
    return <div>error :)</div>
  }

  const { entryPoints } = data;
  const filteredItems = entryPoints.filter(({ origin, destination, sslTtl }) => {
    return (origin && origin.toLowerCase().includes(filterText.toLowerCase())) || 
      (destination && destination.toLowerCase().includes(filterText.toLowerCase())) ||
      (sslTtl && sslTtl.toLowerCase().includes(filterText.toLowerCase()))
  });

  const handleUpdateSubmit = (e) => {
    e.preventDefault();
    updateEntrypoint({variables: {
      id: e.target.id,
      input: {
        origin: e.target.elements.origin.value,
        destination: e.target.elements.destination.value,
        status: JSON.parse(e.target.elements.status.value)
      }
    }});
    closeModal();
  };

  const EditModal = ({ row }) => {
    const { origin, destination, status, id } = row;
    return <form id={id} className="flex flex-column items-center justify-center pa8" onSubmit={handleUpdateSubmit}>
      <Input 
        id="origin"
        type="origin"
        label={t('common:origin')}
        required
        defaultValue={origin}
      />
      <Input 
        id="destination"
        type="destination"
        label={t('common:destination')}
        required
        defaultValue={destination}
      />
      <Select
        label={t('components:leadDetail:editModal:status')}
        id="status"
        required
        defaultValue={status}
      >
        <option value={true}>Active</option>
        <option value={false}>Disabled</option>
      </Select>
      <Button
        primary
        type="submit"
        className="center"
      >
        Save
      </Button>
    </form>
  }

  const DeleteModal = ({ row }) => {
    const { id, origin } = row;
    return <div id={id} className="flex flex-column items-center justify-center pa8">
      <h3 className="fw5">{`Estas seguro de que quieres borrar el entrypoint ${origin}?`}</h3>
      <div className="flex items-center justify-evenly w-100 mt8">
        <Button
          primary
          type="button"
          className="center"
          onClick={(e) => {
            e.preventDefault()
            deleteEntryPoint({ variables: { id }})
            closeModal();
          }}
        >
          Delete
        </Button>
        <Button
          primary
          type="button"
          className="center"
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
      </div>
    </div>
  }

  const RenewModal = ({ row }) => {
    const { id, origin } = row;
    return <div id={id} className="flex flex-column items-center justify-center pa8">
      <h3 className="fw5">{`Quieres renovar el SSL del endpoint: ${origin}?`}</h3>
      <div className="flex items-center justify-evenly w-100 mt8">
        <Button
          primary
          type="button"
          className="center"
          onClick={(e) => {
            e.preventDefault()
            renewEntryPoint({ variables: { id }})
            closeModal();
          }}
        >
          Renew
        </Button>
        <Button
          primary
          type="button"
          className="center"
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
      </div>
    </div>
  }

  const columns = [
    {
      name: 'Origin',
      selector: 'origin',
      sortable: true,
      grow: 2,      
    },
    {
      name: 'destination',
      selector: 'destination',
      sortable: true,
      grow: 2
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      center: true,
      cell: (row) => <Toggle 
        id={row.id}
        checked={row.status}
        onChange={(e) => updateEntrypoint({
          variables: {
            id: row.id,
            input: {
              origin: row.origin,
              destination: row.destination,
              status: e.target.checked
            }
          }})
        } />
    },
    {
      name: 'SSL TTL',
      selector: 'sslTtl',
      sortable: true,
      center: true,
      cell: (row) => {
        const formatDate = row.sslTtl ? dateToLocaleString(row.sslTtl, numericDateFormat) : 'NO SSL TTL';
        return formatDate;
      }
    },
    {
      grow: 1,
      center: true,
      cell: (row) => {
        return <div className="flex items-center justify-evenly w-100">
          <IconButton
            title="Renew SSL"
            onClick={(e) => {
              e.preventDefault()
              return showModal({
              classes: "db center mt7 w-80 ttu",
              children: <RenewModal row={row}/>,
              className: "bg-white"
            })}}
            icon={<ShieldRefreshIcon/>}
          />
          <IconButton
            title="Edit"
            onClick={(e) => {
              e.preventDefault()
              return showModal({
              classes: "db center mt7 w-80 ttu",
              children: <EditModal row={row}/>,
              className: "bg-white"
            })}}
            icon={<PencilIcon/>}
          />
          <IconButton
            title="Delete"
            onClick={(e) => {
              e.preventDefault()
              return showModal({
              classes: "db center mt7 w-80 ttu",
              children: <DeleteModal row={row}/>,
              className: "bg-white"
            })}}
            icon={<TrashIcon/>}
          />
        </div>
      },
      right: true
    }
  ];

  return (
    <div className="entryPoints dataTable">
      <CustomDataTable
        title="Entrypoints List"
        columns={columns}
        data={filteredItems}
        pagination
        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        subHeader
        subHeaderComponentMemo={subHeaderComponentMemo}
        selectableRows
        persistTableHead
        fixedHeader={true}
        fixedHeaderScrollHeight="calc(100vh - 19.75rem - 12px)"
      />
    </div>
  );
}
