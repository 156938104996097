import { login, logout } from 'apollo/typePolicies/users';

const customPolicies = {
  Query: {
    fields: {
      login,
      logout,
    }
  },
  Mutation: {},
  Subscription: {}
}

export default customPolicies;
