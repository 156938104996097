import { gql } from '@apollo/client';

export const GET_ENTRYPOINTS = gql`
  query {
    entryPoints {
      id,
      origin,
      destination,
      status,
      sslTtl
    }
  }
`;

export const UPDATE_ENTRYPOINT = gql`
  mutation updateEntrypoint($id: ID!, $input: EntryPointInput) {
    updateEntryPoint(id: $id, input: $input) {
      id,
      origin,
      destination,
      status
    }
  }
`;

export const DELETE_ENTRYPOINT = gql`
  mutation deleteEntryPoint($id: ID!) {
    deleteEntryPoint(id: $id) {
      n
    }
  }
`;

export const RENEW_ENTRYPOINT = gql`
  mutation renewEntryPoint($id: ID!) {
    renewEntryPoint(id: $id) {
      id,
      origin,
      destination,
      status,
      sslTtl
    }
  }
`;

export const ADD_ENTRYPOINT = gql`
  mutation addEntryPoint($origin: String, $destination: String, $status: Boolean) {
    addEntryPoint(origin: $origin, destination: $destination, status: $status) {
      id,
      origin,
      destination,
      status,
      sslTtl
    }
  }
`;