import React from 'react';
import Spinner from 'common/components/Spinner';
import { filterAttributeProps } from 'utils';
import './Button.scss';

export default function Button({
  children, loading, loadingText = `${t('common:loading')}`,
  primary, secondary, tertiary, translucent,
  disabled, success, error, size,
  onClick, type, className, form,
  style, ...props
}) {
  const _pri = primary ? '-primary' : '';
  const _sec = secondary ? '-secondary' : '';
  const _ter = tertiary ? '-tertiary' : '';
  const _tra = translucent ? '-translucent' : '';
  const _suc = success ? 'success' : '';
  const _err = error ? 'error' : '';
  const _dis = disabled ? 'disabled' : '';
  const selectedSize = size === 's' ? 'small' : (size === 'l' ? 'large' : 'medium');

  const classes = {
    button: `Button${_pri}${_sec}${_ter}${_tra} ${_suc} ${_err} ${_dis} ${selectedSize}`
  }
  
  const ButtonLabel = () => {
    const buttonText = children;
    return !loading ? buttonText : (
      <span className="flex justify-center align-center">
        <Spinner className="w1 h1 mh3 flex self-center mr2 dib"/><span>{loadingText || buttonText}</span>
      </span>
    );
  }

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={loading}
      form={form}
      style={style || {}}
      className={`${classes.button} ${className || ''}`}
      {...filterAttributeProps(props)}
      {...props}
    >
      <ButtonLabel />
    </button>
  );
}
